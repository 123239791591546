import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import io from 'socket.io-client';
import { detectIncognito } from "detectincognitojs";
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import useHttp from './hooks/useHttp';
import useSetIframeStyles from './hooks/useSetIframeStyles';
import { getAthorisationSearchParams } from './utils/getAthorisationSearchParams';
import { base64Audio } from './constants';
import 'react-quill/dist/quill.snow.css';
import './assets/styles/quill.scss';

const authorisationSearchParams = getAthorisationSearchParams();

const audio = new Audio(base64Audio);
window.messageAudio = audio;

const Page = () => {
  const [socketInstance, setSocketInstance] = useState(null);
  const widgetId = useRef(localStorage.getItem('widgetId'));
  const [isTokenChanged, setIsTokenChanged] = useState(false);
  const [isIncognito, setIsIncognito] = useState(null);
  const [isWidgetBlocked, setIsWidgetBlocked] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [authorisationData, authorisationRequest, error] = useHttp(
      `widget/${widgetId.current}?${authorisationSearchParams}`
  );
  useSetIframeStyles({
    display: isWidgetBlocked ? 'none' : 'block',
  });

  const fetchAuthorisationData = useCallback(() => {
    authorisationRequest({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  }, [authorisationRequest]);

  const updateToken = useCallback(() => {
    authorisationRequest({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        widgetId: widgetId.current,
        searchParams: authorisationSearchParams,
      }),
    });
  }, [authorisationRequest]);

  const initializeIncognitoState = async () => {
    const result = await detectIncognito();
    const isIncognitoMode = Number(result.isPrivate);
    localStorage.setItem('isIncognito', isIncognitoMode);
    setIsIncognito(isIncognitoMode);
  };

  useEffect(() => {
    initializeIncognitoState();
  }, []);

  useEffect(() => {
    if (isIncognito !== null && !isWidgetBlocked) {
      fetchAuthorisationData();
    }
  }, [isWidgetBlocked, error, isIncognito, fetchAuthorisationData]);

  useEffect(() => {
    if (error) {
      setIsWidgetBlocked(true);
    }
  }, [error]);

  useEffect(() => {
    if (authorisationData?.data?.token) {
      const { token, expires, refresh, refresh_expires, id: sc_visitor } = authorisationData.data;

      localStorage.setItem('token', token);
      localStorage.setItem('expires', expires);
      localStorage.setItem('refresh', refresh);
      localStorage.setItem('refresh_expires', refresh_expires);
      localStorage.setItem('sc_visitor', sc_visitor);

      setToken(token);
      if (token !== localStorage.getItem('token')) {
      }
    }
  }, [authorisationData]);

  const initializeSocket = useCallback(() => {
    if (socketInstance) {
      socketInstance.off('visitor.status');
      socketInstance.disconnect();
      setSocketInstance(null);
    }

    const localToken = localStorage.getItem('token');
    if (!localToken) return;

    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      query: { token: localToken },
      reconnection: true,
      reconnectionDelay: 500,
    });

    const visitorStatusHandler = (data) => {
      if (data.status === 'blocked') {
        setIsWidgetBlocked(true);
      } else {
        setIsWidgetBlocked(false);
        fetchAuthorisationData();
      }
    };

    newSocket.on('visitor.status', visitorStatusHandler);
    setSocketInstance(newSocket);
  }, [fetchAuthorisationData]);

  useEffect(() => {
    if (!token) return;

    initializeSocket();

    return () => {
      if (socketInstance) {
        socketInstance.off('visitor.status');
        socketInstance.disconnect();
        setSocketInstance(null);
      }
    };
  }, [token, initializeSocket]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const expires = localStorage.getItem('expires');
      if (!expires) return;

      const expirationDate = new Date(expires);
      const now = new Date();

      const minutesBeforeExpiration = (expirationDate - now) / 1000 / 60;

      if (minutesBeforeExpiration <= 2) {
        updateToken();
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 60 * 3000);
    return () => clearInterval(intervalId);
  }, [updateToken]);

  return (
      <App
          widgetId={widgetId.current}
          socketInstance={socketInstance}
          isWidgetBlocked={isWidgetBlocked}
          token={token}
          setIsTokenChanged={setIsTokenChanged}
          initializeSocket={initializeSocket}
      />
  );
};

const root = ReactDOM.createRoot(document.getElementById('widget'));
root.render(<Page />);

reportWebVitals();
