import { useState, useCallback } from 'react';
import { refetchTokens } from '../utils/refetchTokens';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useHttp = (relativeUrl = '') => {


    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const request = useCallback(async (options = {}) => {
        setData(null);
        setError(null);
        setIsLoading(true);

        try {
            let token = localStorage.getItem('token');
            const getOptions = (t) => ({
                headers: { 'Authorization': `Bearer ${t}`, 'Content-Type': 'application/json' },
                ...options
            });


            let response = await fetch(`${BASE_URL}/${relativeUrl}`, getOptions(token));

            if (response.status === 401) {
                await refetchTokens();
                token = localStorage.getItem('token');

                response = await fetch(`${BASE_URL}/${relativeUrl}`, getOptions(token));
            }

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData?.message || 'Произошла ошибка запроса.');
            }

            const responseData = await response.json();

            setData(responseData);

        } catch (err) {
            setError(err.message || 'Не удалось выполнить запрос.');
            console.log('Ошибка:', err.message);
        } finally {
            setIsLoading(false);
        }
    }, [relativeUrl]);

    return [data, request, error, isLoading, setData];
};

export default useHttp;
